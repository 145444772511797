















































































































































































































































































































































































import Vue from "vue";

export default Vue.extend({
  data: () => ({
    // Selection salutation
    items: ["Herr", "Frau"],

    // Datepicker
    date: new Date().toISOString().substr(0, 10),
    menu: false,

    token: "",

    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    phonenumber: "",
    houseNumber: "",
    streetName: "",
    plz: "",
    city: "",
    birthdate: "",
    state: "",
    country: "",

    latitude: "",
    longitude: "",

    profile: {
      sections: [],
      title: "",
      specialization: "",
      workingPlan: {
        monday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
        tuesday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
        wednesday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
        thursday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
        friday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
        saturday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
        sunday: {
          workingHours: {
            start: "",
            end: "",
          },
          breaks: [],
        },
      },
    },

    // Password
    show: false,

    // Requirement rules
    rules: {
      required: (value: string) => !!value || "Pflichtfeld",
      emailMatch: () => "Die Email und das Passwort stimmen nicht über ein",
      isNumber: (value: string) => /^\d+$/.test(value) || "Nur Zahlen erlaubt",
      isEmail: (value: string) =>
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) ||
        "Keine gültige Email",
      isValidPassword: (value: string) =>
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          value
        ) ||
        "Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl, einen Buchstaben und ein Sonderzeichen enthalten",
    },
  }),
  computed: {
    // Generates an array of hours in the format HH:MM in 30 minute intervals
    possibleWorkingTimes(): string[] {
      let times = [];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
          times.push(
            `${i.toString().padStart(2, "0")}:${(j * 30)
              .toString()
              .padStart(2, "0")}`
          );
        }
      }
      return times;
    },
  },
  methods: {
    async register() {
      if (this.password !== this.passwordConfirm) {
        return;
      }
      // @ts-expect-error temp calls vuetify interal
      if (!this.$refs.form.validate()) {
        return;
      }
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        name: this.name,
        email: this.email,
        mobile: this.phonenumber,
        password: this.password,
        address: {
          streetName: this.streetName,
          houseNumber: this.houseNumber,
          city: this.city,
          state: this.state,
          plz: this.plz,
        },
        drs: [],
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      let data = await fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/registration/clinic",
        requestOptions
      );
      let response = await data.text();
      if (response) {
        let token = await this.login(this.email, this.password);
        if (token.startsWith("Bearer")) {
          this.token = token;
        } else {
          throw new Error("Token is not valid");
        }
      }
    },
    addNewDoctorProfile() {
      // @ts-expect-error temp calls vuetify interal
      if (!this.$refs.infoForm.validate()) {
        return;
      }

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.token);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        ...this.profile,
        location: [this.latitude, this.longitude],
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/dr/profile/add/",
        requestOptions
      )
        .then((response) => response.text())
        .then(() => {
          this.$router.push({ path: "home" });
        })
        .catch((error) => console.log("error", error));
    },
    async login(email: string, password: string) {
      let raw = JSON.stringify({
        username: email,
        password: password,
      });

      let requestOptions = {
        method: "POST",
        body: raw,
      };
      let data = await fetch(
        "https://mdic-backend.herokuapp.com/login",
        requestOptions
      );
      let token = await data.text();
      console.log(token);
      return token;
    },
  },
});
